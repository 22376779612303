import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[alphaNumericOnly]'
})
export class AlphaNumericOnly {

	constructor(private el: ElementRef) { }
	@HostListener('input', ['$event']) onInputChange(event) {
		const initalValue = this.el.nativeElement.value;
		this.el.nativeElement.value = initalValue.replace(/[^0-9a-zA-Z ]*/g, '');
		if (initalValue !== this.el.nativeElement.value) {
			event.preventDefault();
		}
	}

}
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Urls } from 'src/app/utils/Urls';

@Injectable({
  providedIn: 'root'
})
export class SettingService {

  constructor(
    private http: HttpClient
  ) { }

  getAppSetting(environment: string): Observable<any>{
    return this.http.get(Urls.settingUrl+`?env=${environment}`);
  }

  getSasToken(secretName: string, secretCode: string): Observable<any>{
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    let params = new HttpParams();
    params = params.set('code', secretCode);
    params = params.set('SecretName', secretName);
    return this.http.get(Urls.keyVaultUrl, {params, headers, responseType: 'text'});
  }
}

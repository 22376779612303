// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //DEV ENV
  envName: "dev",
  bankingApiBaseUrl: "https://api.ihub.dev.omar.oldmutual.com/",
  apiBaseUrl: "https://roa-dev-agaugeatbpf3gcb3.z01.azurefd.net/",

  //UAT env
  // accountName : "road365uatstorageaccount",
  // sasToken:"?sv=2021-12-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2023-03-16T21:33:03Z&st=2023-03-16T13:33:03Z&spr=https,http&sig=com71TxIpYk6ZSUSlaBNQc2tcDUH4cWjLOTUrYBQ518%3D",
  // secretName: "sastokenuat",
  // secretCode: "8QJ9EvxvAIoS5y206vyrUj9ABe2uCUuOSUmVXTAqJ1w6AzFuvWU6Hg==",
  // bankSubscriptionKey: "7d393c083b564684a388ce99eb8633c9",
  // bankAccountName: "omarbankintegrations",
  // bankContainerName: "bank-integration-sftp",
  // bankSasToken: "?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupyx&se=2023-08-12T19:25:24Z&st=2023-08-12T11:25:24Z&spr=https,http&sig=T%2BHK%2BiRzA7VX7WAMA%2BsPw8SfmhlTTmZXDXRtm9mWX8E%3D",
  // bankingApiBaseUrl: "https://api.ihub.uat.omar.oldmutual.com/",
  // bankSecretName: "sastoeknuatbankintegration",
  // bankSecretCode: "8QJ9EvxvAIoS5y206vyrUj9ABe2uCUuOSUmVXTAqJ1w6AzFuvWU6Hg==",
  // apiBaseUrl: "https://roa-uat-bmb2b4c5dfhpbwek.z01.azurefd.net/",

  //SIT ENV
  // accountName : "omard365stagingstorage",
  // sasToken:"sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupyx&se=2025-07-17T18:27:06Z&st=2024-07-17T10:27:06Z&spr=https,http&sig=ROtlFCsxf%2BQkPpuVE207IWM0WwyVdoeSRenDbkBNAT0%3D",
  // secretName: "",
  // secretCode: "",
  // bankSubscriptionKey: "847c73451c8149a2bbab3a5865fbebd5",
  // bankAccountName: "omard365stagingstorage",
  // bankContainerName: "bank-integration-sftp",
  // bankSasToken: "sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupyx&se=2025-07-17T18:27:06Z&st=2024-07-17T10:27:06Z&spr=https,http&sig=ROtlFCsxf%2BQkPpuVE207IWM0WwyVdoeSRenDbkBNAT0%3D",
  // bankingApiBaseUrl: "https://roasitapigw.azure-api.net/",
  // bankSecretName: "",
  // bankSecretCode: "",
  // apiBaseUrl: "https://omar-staging-ehbxfscmagd9d5gr.z01.azurefd.net/"

  //PROD env
  // accountName : "omarftp",
  // sasToken:"sp=racw&st=2023-04-03T06:45:54Z&se=2023-07-03T14:45:54Z&spr=https&sv=2021-12-02&sr=c&sig=vu5UI4D8iqxFBgHP7EV%2FmaZoRQ1HDSfdKDqG276J3CY%3D",
  // secretName: "sastokenprod",
  // secretCode: "dpaZQMqv8Pp4-AqRwy3yTuk4AuXB3PKyhsDt-IpNgZQOAzFu8yE9Cw==",
  // bankSubscriptionKey: "d8b2fb16f41241a1a8558201e963eafe",
  // bankAccountName: "omarftp",
  // bankContainerName: "bank-integration-sftp",
  // bankSasToken: "?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupyx&se=2023-12-17T21:39:16Z&st=2023-09-01T13:39:16Z&spr=https,http&sig=XE0P25XxeGMk9bidyEEF%2Bzt%2BdbM3fsrb2M69sfNBDgo%3D",
  // bankingApiBaseUrl: "https://api.ihub.omar.oldmutual.com/",
  // bankSecretName: "sastokenbankintegrationprod",
  // bankSecretCode: "dpaZQMqv8Pp4-AqRwy3yTuk4AuXB3PKyhsDt-IpNgZQOAzFu8yE9Cw==",
  // apiBaseUrl: "https://omar-prod-cqg8hre0dnbghze4.z01.azurefd.net/"

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

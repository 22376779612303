import { Component, Input, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { DialogLogoutConfirmationComponent } from '../dialog-logout-confirmation/dialog-logout-confirmation.component';
import { Observable } from 'rxjs';
import { Breadcrumb } from 'src/app/utils/breadcrumb';
import { BreadcrumbService } from 'src/app/services/breadcrumb/breadcrumb.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {

  @Input() backTitle: string;

  username = "";
  markedIcon:boolean;
  breadcrumbs$: any;

  loginDisplay = false;
  displayedColumns: string[] = ['claim', 'value'];
  dataSource: any =[];

  private readonly _destroying$ = new Subject<void>();

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private authService: MsalService, 
    private msalBroadcastService: MsalBroadcastService,
    private readonly breadcrumbService: BreadcrumbService
  ) { 
    //this.username = localStorage.getItem("username")
    this.breadcrumbs$ = this.breadcrumbService.breadcrumbs$.source;
  }

  ngOnInit() {
    setTimeout(()=>{                           
      this.getUserDetails();
  }, 2000);
    
    // this.msalBroadcastService.msalSubject$
    // .pipe(
    //   filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
    //   takeUntil(this._destroying$)
    // )
    // .subscribe((result: EventMessage) => {
    //   console.log(result);
    //   const payload = result.payload as AuthenticationResult;
    //   this.authService.instance.setActiveAccount(payload.account);
    // });

    // this.msalBroadcastService.inProgress$
    // .pipe(
    //   filter((status: InteractionStatus) => status === InteractionStatus.None)
    // )
    // .subscribe(() => {
    //   this.setLoginDisplay();
    //   this.checkAndSetActiveAccount();
    //   this.getClaims(this.authService.instance.getActiveAccount()?.idTokenClaims)
    // });
  }

  logout() {
    const dialogRef = this.dialog.open(DialogLogoutConfirmationComponent);

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      if(result){
        localStorage.clear();
        // this.router.navigate(["/login"])
        this.authLogout();
      }
    });
  }

  checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    let activeAccount = this.authService.instance.getActiveAccount();

    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      let accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  authLogout(){
    this.authService.logout();
  }

  getClaims(claims: any) {

  }

  getUserDetails(){
    let activeAccount: any[] = this.authService.instance.getAllAccounts();
    if(activeAccount.length > 0){
      this.username = activeAccount[0].idTokenClaims.name;
    }
  }

  onNotificationOpen(){
    this.router.navigateByUrl("/notifications");
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

}


  <div id="dashboard-wrapper" class="component-wrapper">
    <app-sidebar></app-sidebar>

    <div id="dashboard-content" class="router-wraper">
      <app-header></app-header>
      <div class="page-container">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>


$("body").on("click", "nav > ul > li > a", function (event) {
  var _this = $(this);
  if (_this.closest('li').hasClass('submenu-level') && !_this.closest('li').hasClass('open-menu')) {
    $("nav > ul > li").removeClass('open-menu');
    $("nav > ul > li .submenu").addClass('menu-hide').removeClass('menu-show')
    $('nav li .submenu .has-submenu').find('.submenu-level-2').addClass('menu-hide').removeClass('menu-show')
    $("nav li .submenu .has-submenu").removeClass('open-menu')
    _this.closest('li').find('.submenu').addClass('menu-show').removeClass('menu-hide')
    _this.closest('li').addClass('open-menu')
  } else if (_this.closest('li').hasClass('open-menu')) {
    _this.closest('li').find('.submenu').removeClass('menu-show')
    _this.closest('li').removeClass('open-menu')
  } else {
    $("nav > ul > li").removeClass('open-menu');
    $("nav > ul > li .submenu").addClass('menu-hide').removeClass('menu-show')
    $('nav li .submenu .has-submenu').find('.submenu-level-2').addClass('menu-hide').removeClass('menu-show')
    $("nav li .submenu .has-submenu").removeClass('open-menu')
  }
  if ($('nav li .submenu .has-submenu .submenu-level-2').hasClass('menu-show')) {
    $('nav li .submenu').removeClass('submenu-border')
  } else {
    $('nav li .submenu').addClass('submenu-border')
  }

  checkUrl()
})

$("body").on("click", "nav li .submenu .has-submenu > a", function () {
  var _this = $(this);
  $('nav li .submenu .has-submenu').find('.submenu-level-2').addClass('menu-hide').removeClass('menu-show')
  if (!_this.closest('li').hasClass('open-menu')) {
    $('nav li .submenu .has-submenu').removeClass('open-menu')
    _this.closest('li').find('.submenu-level-2').addClass('menu-show').removeClass('menu-hide')
    _this.closest('li').addClass('open-menu')
  } else {
    _this.closest('li').find('.submenu-level-2').addClass('menu-hide').removeClass('menu-show')
    _this.closest('li').removeClass('open-menu')
  }

  if ($('nav li .submenu .has-submenu .submenu-level-2').hasClass('menu-show')) {
    $('nav li .submenu').removeClass('submenu-border')
  } else {
    $('nav li .submenu').addClass('submenu-border')
  }
  checkUrl()
});

$("body").on("click", "nav li .submenu .has-submenu .submenu-level-2", function () {
  if ($('nav li .submenu .has-submenu .submenu-level-2 .mat-focus-indicator').hasClass('active')) {
    checkUrl()
  }
})

$("body").on("click", ".expand-menu-button", function () {
  $("nav > ul > li").removeClass('open-menu');
  $("nav > ul > li .submenu").addClass('menu-hide').removeClass('menu-show')
  $('nav li .submenu .has-submenu').find('.submenu-level-2').addClass('menu-hide').removeClass('menu-show')
  $("nav li .submenu .has-submenu").removeClass('open-menu')
  checkUrl()
});

$("body").on("click", ".expand-menu-button", function () {
  $('.component-wrapper').toggleClass('sidebar-open')
  $('.menu-drawer').toggleClass('menu-drawer-open')
  checkUrl()
});

$(window).resize(function () {
  let width = $(document).width();
  if (width >= 767) {
    $('.menu-drawer').addClass('menu-drawer-open')
    $('.component-wrapper').removeClass('sidebar-open')
  }
  if (width <= 767) {
    $('.menu-drawer').removeClass('menu-drawer-open')
  }
})

$(document).ready(function () {
  let width = $(document).width();
  setTimeout(() => {
    if (width <= 767) {
      $('.menu-drawer').removeClass('menu-drawer-open')
    }
  }, 200);
  checkUrl()
});

const checkUrl = () => {
  const queryString = window.location.href
  if (!queryString.includes('banking-integration/configuration')) {
    localStorage.removeItem('searchBankConnection')
  }
}



<div class="dialog-wrapper">
  <h1 mat-dialog-title class="text-center">Logout</h1>
  <div mat-dialog-content>
    Do you really want to logout?
  </div>
  <div mat-dialog-actions class="mat-dialog-actions">
    <button mat-flat-button class="btn secondary-btn" mat-dialog-close>CANCEL</button>
    <button mat-flat-button class="btn primary-btn" [mat-dialog-close]="true">LOGOUT</button>
  </div>
</div>

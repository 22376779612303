import {NgModule} from '@angular/core';
import {APOLLO_OPTIONS} from 'apollo-angular';
import {ApolloClientOptions, createHttpLink, InMemoryCache} from '@apollo/client/core';
import {HttpLink} from 'apollo-angular/http';

import { setContext } from '@apollo/client/link/context';
import { environment } from 'src/environments/environment';

const uri = environment.apiBaseUrl + "graphql";


const customHttpLink = createHttpLink({
  uri: uri,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('accessToken');
  
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    }
  }
});



export function createApollo(httpLink: HttpLink): ApolloClientOptions<any> {
  return {
    //link: httpLink.create({uri}),
    link: authLink.concat(customHttpLink),
    cache: new InMemoryCache(),
    queryDeduplication: true
  };
}

@NgModule({
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink],
    },
  ],
})
export class GraphQLModule {}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, Data, NavigationEnd } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { Breadcrumb } from 'src/app/utils/breadcrumb';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService {

  private readonly _breadcrumbs$ = new BehaviorSubject<Breadcrumb[]>([]);

  readonly breadcrumbs$ = this._breadcrumbs$.asObservable();

  constructor(private router: Router) {
    this.router.events.pipe(

      filter((event) => event instanceof NavigationEnd)
    ).subscribe(event => {

      const root = this.router.routerState.snapshot.root;
      const breadcrumbs: Breadcrumb[] = [];
      this.addBreadcrumb(root, [], breadcrumbs);

   
      this._breadcrumbs$.next(breadcrumbs);
    });
  }

  private addBreadcrumb(route: ActivatedRouteSnapshot, parentUrl: string[], breadcrumbs: Breadcrumb[]) {
    if (route) {
  
      const routeUrl = parentUrl.concat(route.url.map(url => url.path));

      if (route.data.breadcrumb) {
        const breadcrumb = {
          label: this.getLabel(route.data),
          url: '/' + routeUrl.join('/')
        };
        breadcrumbs.push(breadcrumb);
      }

      if (routeUrl.join('/') == 'dimension/channel' || routeUrl.join('/') == 'dimension/segment' ||
        routeUrl.join('/') == 'dimension/region' || routeUrl.join('/') == 'dimension/portfolio' || routeUrl.join('/') == 'dimension/department' || routeUrl.join('/') == 'dimension/project' || routeUrl.join('/') == 'dimension/intercompany' || routeUrl.join('/') == 'dimension/pns' || routeUrl.join('/') == 'dimension/transactiontype') {
        route.data = {
          breadcrumb: [
            {
              label: 'D365',
              isLink: true,
              url: ''
            },
            {
              label: 'Dimensions',
              isLink: true,
              url: ''
            },
            {
              label: route.params.dimensionMasterName == 'intercompany' ? 'Inter-company': route.params.dimensionMasterName == 'transactiontype' ? 'Transaction Type': route.params.dimensionMasterName,
              isLink: false,
              url: '/' + routeUrl.join('/')
            }
          ],
        }
        const breadcrumb = {
          label: this.getLabel(route.data),
          url: '/' + routeUrl.join('/')
        };
        breadcrumbs.push(breadcrumb);
      }

      if (routeUrl.join('/') == 'mapping/lineofbusiness' || routeUrl.join('/') == 'mapping/channel' || routeUrl.join('/') == 'mapping/segment' || routeUrl.join('/') == 'mapping/branch' || routeUrl.join('/') == 'mapping/product' || routeUrl.join('/') == 'mapping/region' || routeUrl.join('/') == 'mapping/portfolio' || routeUrl.join('/') == 'mapping/department' || routeUrl.join('/') == 'mapping/project' || routeUrl.join('/') == 'mapping/intercompany' || routeUrl.join('/') == 'mapping/pns' || routeUrl.join('/') == 'mapping/transactiontype') {
        route.data = {
          breadcrumb: [
            {
              label: 'Mappings',
              isLink: true,
              url: ''
            },
            {
              label: 'Dimensions',
              isLink: true,
              url: ''
            },
            {
              label: route.params.mappingMasterName == 'lineofbusiness' ? "Line Of Business" : route.params.mappingMasterName == 'transactiontype' ? "Transaction Type" : route.params.mappingMasterName == 'intercompany' ? "Inter-Company" : route.params.mappingMasterName,
              isLink: false,
              url: '/' + routeUrl.join('/')
            }
          ],
        }
        const breadcrumb = {
          label: this.getLabel(route.data),
          url: '/' + routeUrl.join('/')
        };
        breadcrumbs.push(breadcrumb);
      }

      if (route.queryParams.type == 'review') {
        route.data = {

          breadcrumb: [
            {
              label: 'ETL Configuration',
              isLink: true,
              url: ''
            },
            {
              label: 'Configuration management',
              isLink: true,
              url: ''
            },
            {
              label: 'Integration Review',
              isLink: false,
              url: 'integration-review'
            },
            {
              label: 'Review',
              isLink: false,
              url: 'integration-view'
            }
          ],

        }
        const breadcrumb = {
          label: this.getLabel(route.data),
          url: '/' + routeUrl.join('/')
        };
        breadcrumbs.push(breadcrumb);
      }
      if (route.queryParams.type == 'approve') {
        route.data = {

          breadcrumb: [
            {
              label: 'ETL Configuration',
              isLink: true,
              url: ''
            },
            {
              label: 'Configuration management',
              isLink: true,
              url: ''
            },
            {
              label: 'Integration Approval',
              isLink: false,
              url: 'integration-approval'
            },
            {
              label: 'Review',
              isLink: false,
              url: 'integration-view'
            }
          ],

        }
        const breadcrumb = {
          label: this.getLabel(route.data),
          url: '/' + routeUrl.join('/')
        };
        breadcrumbs.push(breadcrumb);
      }
      breadcrumbs.splice(1)

      this.addBreadcrumb(route.firstChild, routeUrl, breadcrumbs);
    }
  }

  private getLabel(data: Data) {
    return typeof data.breadcrumb === 'function' ? data.breadcrumb(data) : data.breadcrumb;
  }
}

import { NgModule } from '@angular/core';
import { PreloadAllModules, Router, RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';

const routes: Routes = [
  // {
  //   path: '',
  //   pathMatch: "prefix",
  //   component: MainComponent,
  //   children: [
  //     {
  //       path: 'home',
  //       loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule),
  //     },
  //   ]
  // },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule),
    canActivate: [MsalGuard],
    data: { breadcrumb: [
      {
        label: 'Home',
        isLink: false,
        url: 'home'
      },
    ] }
  },
  {
    path: 'region-master',
    loadChildren: () => import('./pages/master/region-master/region-master.module').then(m => m.RegionMasterModule),
    canActivate: [MsalGuard]
  },
  {
    path: 'country-master',
    loadChildren: () => import('./pages/master/country-master/country-master.module').then(m => m.CountryMasterModule),
    canActivate: [MsalGuard]
  },
  {
    path: 'source-system-type-master',
    loadChildren: () => import('./pages/master/source-system-type-master/source-system-type-master.module').then(m => m.SourceSystemTypeMasterModule),
    canActivate: [MsalGuard]
  },
  {
    path: 'master/source-system',
    loadChildren: () => import('./pages/master/source-system-master/source-system-master.module').then(m => m.SourceSystemMasterModule),
    canActivate: [MsalGuard],
    data: {
      breadcrumb: [
        {
          label: 'ETL Configuration',
          isLink: true,
          url: ''
        },
        {
          label: 'Configuration masters',
          isLink: true,
          url: ''
        },
        {
          label: 'Source Systems',
          isLink: false,
          url: 'master/source-system'
        }
      ],
    }
  },
  {
    path: 'master/source-data-category',
    loadChildren: () => import('./pages/master/source-data-category-master/source-data-category-master.module').then(m => m.SourceDataCategoryMasterModule),
    canActivate: [MsalGuard],
    data: {
      breadcrumb: [
        {
          label: 'ETL Configuration',
          isLink: true,
          url: ''
        },
        {
          label: 'Configuration masters',
          isLink: true,
          url: ''
        },
        {
          label: 'Source Data Category',
          isLink: false,
          url: 'master/source-data-category'
        }
      ],
    }
  },
  {
    path: 'master/source-data-from',
    loadChildren: () => import('./pages/master/source-data-from-master/source-data-from-master.module').then(m => m.SourceDataFromMasterModule),
    canActivate: [MsalGuard],
    data: {
      breadcrumb: [
        {
          label: 'ETL Configuration',
          isLink: true,
          url: ''
        },
        {
          label: 'Configuration masters',
          isLink: true,
          url: ''
        },
        {
          label: 'Source Data From',
          isLink: false,
          url: 'master/source-data-from'
        }
      ],
    }
  },
  {
    path: 'master/inbound-table-name',
    loadChildren: () => import('./pages/master/inbound-table-name-master/inbound-table-name-master.module').then(m => m.InboundTableNameMasterModule),
    canActivate: [MsalGuard],
    data: {
      breadcrumb: [
        {
          label: 'ETL Configuration',
          isLink: true,
          url: ''
        },
        {
          label: 'Configuration masters',
          isLink: true,
          url: ''
        },
        {
          label: 'Inbound Table Name',
          isLink: false,
          url: 'master/inbound-table-name'
        }
      ],
    }
  },
  {
    path: 'master/outbound-table-name',
    loadChildren: () => import('./pages/master/outbound-table-name-master/outbound-table-name-master.module').then(m => m.OutboundTableNameMasterModule),
    canActivate: [MsalGuard],
    data: {
      breadcrumb: [
        {
          label: 'ETL Configuration',
          isLink: true,
          url: ''
        },
        {
          label: 'Configuration masters',
          isLink: true,
          url: ''
        },
        {
          label: 'Outbound Table Name',
          isLink: false,
          url: 'master/outbound-table-name'
        }
      ],
    }
  },
  {
    path: 'master/validation-tier',
    loadChildren: () => import('./pages/master/validation-tier-master/validation-tier-master.module').then(m => m.ValidationTierMasterModule),
    canActivate: [MsalGuard],
    data: {
      breadcrumb: [
        {
          label: 'ETL Configuration',
          isLink: true,
          url: ''
        },
        {
          label: 'Configuration masters',
          isLink: true,
          url: ''
        },
        {
          label: 'Validation Tier',
          isLink: false,
          url: 'master/validation-tier'
        }
      ],
    }
  },
  {
    path: 'master/dvf-type',
    loadChildren: () => import('./pages/master/dvf-type-master/dvf-type-master.module').then(m => m.DVFTypeMasterModule),
    canActivate: [MsalGuard],
    data: {
      breadcrumb: [
        {
          label: 'ETL Configuration',
          isLink: true,
          url: ''
        },
        {
          label: 'Configuration masters',
          isLink: true,
          url: ''
        },
        {
          label: 'DVF Type',
          isLink: false,
          url: 'master/dvf-type'
        }
      ],
    }
  },
  {
    path: 'master/dt-type',
    loadChildren: () => import('./pages/master/dt-type-master/dt-type-master.module').then(m => m.DTTypeMasterModule),
    canActivate: [MsalGuard],
    data: {
      breadcrumb: [
        {
          label: 'ETL Configuration',
          isLink: true,
          url: ''
        },
        {
          label: 'Configuration masters',
          isLink: true,
          url: ''
        },
        {
          label: 'DT Type',
          isLink: false,
          url: 'master/dt-type'
        }
      ],
    }
  },
  {
    path: 'master/validation-rule',
    loadChildren: () => import('./pages/master/validation-rule-master/validation-rule-master.module').then(m => m.ValidationRuleMasterModule),
    canActivate: [MsalGuard],
    data: {
      breadcrumb: [
        {
          label: 'ETL Configuration',
          isLink: true,
          url: ''
        },
        {
          label: 'Configuration masters',
          isLink: true,
          url: ''
        },
        {
          label: 'Validation Rule',
          isLink: false,
          url: 'master/validation-rule'
        }
      ],
    }
  },
  {
    path: 'master/transformation-rule',
    loadChildren: () => import('./pages/master/transformation-rule-master/transformation-rule-master.module').then(m => m.TransformationRuleMasterModule),
    canActivate: [MsalGuard],
    data: {
      breadcrumb: [
        {
          label: 'ETL Configuration',
          isLink: true,
          url: ''
        },
        {
          label: 'Configuration masters',
          isLink: true,
          url: ''
        },
        {
          label: 'Transformation Rule',
          isLink: false,
          url: 'master/transformation-rule'
        }
      ],
    }
  },
  {
    path: 'frequency-type-master',
    loadChildren: () => import('./pages/master/frequency-type-master/frequency-type-master.module').then(m => m.FrequencyTypeMasterModule),
    canActivate: [MsalGuard]
  },
  {
    path: 'ftp-user-role-master',
    loadChildren: () => import('./pages/master/ftp-user-role-master/ftp-user-role-master.module').then(m => m.FtpUserRoleMasterModule),
    canActivate: [MsalGuard]
  },
  {
    path: 'master/integration-item',
    loadChildren: () => import('./pages/master/integration-item-master/integration-item-master.module').then(m => m.IntegrationItemMasterModule),
    canActivate: [MsalGuard],
    data: {
      breadcrumb: [
        {
          label: 'ETL Configuration',
          isLink: true,
          url: ''
        },
        {
          label: 'Configuration masters',
          isLink: true,
          url: ''
        },
        {
          label: 'Integration Item',
          isLink: false,
          url: 'master/integration-item'
        }
      ],
    }
  },
  {
    path: 'master/interface-type',
    loadChildren: () => import('./pages/master/interface-type-master/interface-type-master.module').then(m => m.InterfaceTypeMasterModule),
    canActivate: [MsalGuard],
    data: {
      breadcrumb: [
        {
          label: 'ETL Configuration',
          isLink: true,
          url: ''
        },
        {
          label: 'Configuration masters',
          isLink: true,
          url: ''
        },
        {
          label: 'Interface Type',
          isLink: false,
          url: 'master/interface-type'
        }
      ],
    }
  },
  {
    path: 'legal-entity-master',
    loadChildren: () => import('./pages/master/legal-entity-master/legal-entity-master.module').then(m => m.LegalEntityMasterModule),
    canActivate: [MsalGuard]
  },
  {
    path: 'process-type-master',
    loadChildren: () => import('./pages/master/process-type-master/process-type-master.module').then(m => m.ProcessTypeMasterModule),
    canActivate: [MsalGuard]
  },
  {
    path: 'line-of-business-master',
    loadChildren: () => import('./pages/master/line-of-business-master/line-of-business-master.module').then(m => m.LineOfBusinessMasterModule),
    canActivate: [MsalGuard]
  },
  {
    path: 'financial-dimensions-master',
    loadChildren: () => import('./pages/master/financial-dimensions-master/financial-dimensions-master.module').then(m => m.FinancialDimensionsMasterModule),
    canActivate: [MsalGuard]
  },
  {
    path: 'chart-of-accounts-master',
    loadChildren: () => import('./pages/master/chart-of-accounts-master/chart-of-accounts-master.module').then(m => m.ChartOfAccountsMasterModule),
    canActivate: [MsalGuard]
  },
  {
    path: 'integration-setup',
    loadChildren: () => import('./pages/integration-list/integration-list.module').then(m => m.IntegrationListModule),
    canActivate: [MsalGuard],
    data: {
      breadcrumb: [
        {
          label: 'ETL Configuration',
          isLink: true,
          url: ''
        },
        {
          label: 'Configuration management',
          isLink: true,
          url: ''
        },
        {
          label: 'Integration Setup',
          isLink: false,
          url: 'integration-setup'
        }
      ],
    }
  },
  {
    path: 'integration-view',
    loadChildren: () => import('./pages/integration-view/integration-view.module').then(m => m.IntegrationViewModule),
    canActivate: [MsalGuard],
  },
  {
    path: 'integration-review',
    loadChildren: () => import('./pages/integration-review-list/integration-review-list.module').then(m => m.IntegrationReviewListModule),
    canActivate: [MsalGuard],
    data: {
      breadcrumb: [
        {
          label: 'ETL Configuration',
          isLink: true,
          url: ''
        },
        {
          label: 'Configuration management',
          isLink: true,
          url: ''
        },
        {
          label: 'Integration Review',
          isLink: false,
          url: 'integration-review'
        }
      ],
    }
  },
  {
    path: 'integration-approval',
    loadChildren: () => import('./pages/integration-approve-list/integration-approve-list.module').then(m => m.IntegrationApproveListModule),
    canActivate: [MsalGuard],
    data: {
      breadcrumb: [
        {
          label: 'ETL Configuration',
          isLink: true,
          url: ''
        },
        {
          label: 'Configuration management',
          isLink: true,
          url: ''
        },
        {
          label: 'Integration Approval',
          isLink: false,
          url: 'integration-approval'
        }
      ],
    }
  },
  {
    path: 'integration-add',
    loadChildren: () => import('./pages/integration-add/integration-add.module').then(m => m.IntegrationAddModule),
    canActivate: [MsalGuard]
  },
  {
    path: 'integration-flow',
    loadChildren: () => import('./pages/integration-flow/integration-flow.module').then(m => m.IntegrationFlowModule),
    canActivate: [MsalGuard],
    data: {
      breadcrumb: [
        {
          label: 'ETL Configuration',
          isLink: true,
          url: ''
        },
        {
          label: 'Configuration management',
          isLink: true,
          url: ''
        },
        {
          label: 'Integration Setup',
          isLink: false,
          url: 'integration-setup'
        },
        {
          label: 'Integration Item Flow',
          isLink: false,
          url: 'integration-flow'
        }
      ],
    }
  },
  {
    path: 'main-account',
    loadChildren: () => import('./pages/dimension/main-account/main-account.module').then(m => m.MainAccountModule),
    canActivate: [MsalGuard],
    data: {
      breadcrumb: [
        {
          label: 'D365',
          isLink: true,
          url: ''
        },
        {
          label: 'Chart of Accounts',
          isLink: true,
          url: ''
        },
        {
          label: 'Main Account',
          isLink: false,
          url: '/main-account'
        }
      ],
    }
  },
  {
    path: 'main-account-category',
    loadChildren: () => import('./pages/dimension/main-account-categories/main-account-categories.module').then(m => m.MainAccountCategoriesModule),
    canActivate: [MsalGuard],
    data: {
      breadcrumb: [
        {
          label: 'D365',
          isLink: true,
          url: ''
        },
        {
          label: 'Chart of Accounts',
          isLink: true,
          url: ''
        },
        {
          label: 'Main Account Categories',
          isLink: false,
          url: '/main-account-category'
        }
      ],
    }
  },
  {
    path: 'dimension/country-cluster',
    loadChildren: () => import('./pages/dimension/dimension-country-cluster/dimension-country-cluster.module').then(m => m.DimensionCountryClusterModule),
    canActivate: [MsalGuard],
    data: {
      breadcrumb: [
        {
          label: 'D365',
          isLink: true,
          url: ''
        },
        {
          label: 'Dimensions',
          isLink: true,
          url: ''
        },
        {
          label: 'Country Clusters',
          isLink: false,
          url: '/dimension/country-cluster'
        }
      ],
    }
  },
  {
    path: 'dimension/country',
    loadChildren: () => import('./pages/dimension/dimension-country/dimension-country.module').then(m => m.DimensionCountryModule),
    canActivate: [MsalGuard],
    data: {
      breadcrumb: [
        {
          label: 'D365',
          isLink: true,
          url: ''
        },
        {
          label: 'Dimensions',
          isLink: true,
          url: ''
        },
        {
          label: 'Country',
          isLink: false,
          url: '/dimension/country'
        }
      ],
    }
  },
  {
    path: 'dimension/legal-entity',
    loadChildren: () => import('./pages/dimension/dimension-legal-entity/dimension-legal-entity.module').then(m => m.DimensionLegalEntityModule),
    canActivate: [MsalGuard],
    data: {
      breadcrumb: [
        {
          label: 'D365',
          isLink: true,
          url: ''
        },
        {
          label: 'Dimensions',
          isLink: true,
          url: ''
        },
        {
          label: 'Legal Entity',
          isLink: false,
          url: '/dimension/legal-entity'
        }
      ],
    }
  },
  {
    path: 'dimension/line-of-business',
    loadChildren: () => import('./pages/dimension/dimension-lob/dimension-lob.module').then(m => m.DimensionLobModule),
    canActivate: [MsalGuard],
    data: {
      breadcrumb: [
        {
          label: 'D365',
          isLink: true,
          url: ''
        },
        {
          label: 'Dimensions',
          isLink: true,
          url: ''
        },
        {
          label: 'Line of Business',
          isLink: false,
          url: '/dimension/line-of-business'
        }
      ],
    }
  },
  {
    path: 'dimension/area',
    loadChildren: () => import('./pages/dimension/dimension-area/dimension-area.module').then(m => m.DimensionAreaModule),
    canActivate: [MsalGuard],
    data: {
      breadcrumb: [
        {
          label: 'D365',
          isLink: true,
          url: ''
        },
        {
          label: 'Dimensions',
          isLink: true,
          url: ''
        },
        {
          label: 'Area',
          isLink: false,
          url: '/dimension/area'
        }
      ],
    }
  },
  {
    path: 'dimension/branch',
    loadChildren: () => import('./pages/dimension/dimension-branch/dimension-branch.module').then(m => m.DimensionBranchModule),
    canActivate: [MsalGuard],
    data: {
      breadcrumb: [
        {
          label: 'D365',
          isLink: true,
          url: ''
        },
        {
          label: 'Dimensions',
          isLink: true,
          url: ''
        },
        {
          label: 'Branch',
          isLink: false,
          url: '/dimension/branch'
        }
      ],
    }
  },
  {
    path: 'dimension/product',
    loadChildren: () => import('./pages/dimension/dimension-product/dimension-product.module').then(m => m.DimensionProductModule),
    canActivate: [MsalGuard],
    data: {
      breadcrumb: [
        {
          label: 'D365',
          isLink: true,
          url: ''
        },
        {
          label: 'Dimensions',
          isLink: true,
          url: ''
        },
        {
          label: 'Product',
          isLink: false,
          url: '/dimension/product'
        }
      ],
    }
  },
  {
    path: 'dimension/:dimensionMasterName',
    loadChildren: () => import('./pages/dimension/dimension-master/dimension-master.module').then(m => m.DimensionMasterModule),
    canActivate: [MsalGuard],
  },
  {
    path: 'mapping/main-account',
    loadChildren: () => import('./pages/mapping/mapping-chart-of-account/mapping-chart-of-account.module').then(m => m.MappingChartOfAccountModule),
    canActivate: [MsalGuard],
    data: {
      breadcrumb: [
        {
          label: 'Mappings',
          isLink: true,
          url: ''
        },
        {
          label: 'Main Account',
          isLink: false,
          url: 'mapping/main-account'
        }
      ],
    }
  },
  {
    path: 'mapping/:mappingMasterName',
    loadChildren: () => import('./pages/mapping/mapping-master/mapping-master.module').then(m => m.MappingMasterModule),
    canActivate: [MsalGuard]
  },
  {
    path: 'user',
    loadChildren: () => import('./pages/design/user/user.module').then(m => m.UserPageModule),

  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'pagenotfound',
    loadChildren: () => import('./pages/pagenotfound/pagenotfound.module').then(m => m.PagenotfoundModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule),
  },
  {
    path: 'code',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule),
  },
  {
    path: 'state',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule),
  },
  {
    path: 'hash',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule),
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./pages/design/dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'data-table',
    loadChildren: () => import('./pages/data-table/data-table.module').then(m => m.DataTableModule),
    // canActivate: [AuthGuard]
  },
  {
    path: 'data-table-dynamic-api',
    loadChildren: () => import('./pages/data-table2/data-table2.module').then(m => m.DataTable2Module),
    // canActivate: [AuthGuard]
  },
  {
    path: 'data-table-advance',
    loadChildren: () => import('./pages/data-table3/data-table3.module').then(m => m.DataTable3Module),
    // canActivate: [AuthGuard]
  },
  {
    path: 'set-one',
    loadChildren: () => import('./pages/common/setone/setone.module').then(m => m.SetoneModule)
  },
  {
    path: 'set-two',
    loadChildren: () => import('./pages/common/settwo/settwo.module').then(m => m.SettwoModule)
  },
  {
    path: 'set-table',
    loadChildren: () => import('./pages/common/settable/settable.module').then(m => m.SettableModule)
  },
  {
    path: 'set-table-2',
    loadChildren: () => import('./pages/common/settable2/settable2.module').then(m => m.Settable2Module)
  },
  {
    path: 'set-table-3',
    loadChildren: () => import('./pages/common/settable3/settable3.module').then(m => m.Settable3Module)
  },
  {
    path: 'set-3',
    loadChildren: () => import('./pages/common/setthree/setthree.module').then(m => m.SetthreeModule)
  },
  {
    path: 'user-profile2',
    loadChildren: () => import('./pages/design/my-profile/my-profile.module').then(m => m.MyProfileModule)
  },
  {
    path: 'setting',
    loadChildren: () => import('./pages/design/setting/setting.module').then(m => m.SettingModule)
  },
  {
    path: 'splash',
    loadChildren: () => import('./pages/design/splash-screen/splash-screen.module').then(m => m.SplashScreenModule)
  },
  {
    path: 'set-4',
    loadChildren: () => import('./pages/common/setfour/setfour.module').then(m => m.SetfourModule)
  },
  {
    path: 'country-clusters',
    loadChildren: () => import('./pages/country-clusters/country-clusters.module').then(m => m.CountryClustersModule)
  },
  // {
  //   path: 'upload-file',
  //   loadChildren: () => import('./pages/upload-file/upload-file.module').then( m => m.UploadFileModule)
  // },
  {
    path: 'upload-file',
    loadChildren: () => import('./pages/file-upload/file-upload.module').then(m => m.FileUploadModule),
    canActivate: [MsalGuard],
    data: {
      breadcrumb: [
        {
          label: 'Upload File',
          isLink: false,
          url: 'upload-file'
        }
      ],
    }
  },
  {
    path: 'view-uploaded-file',
    loadChildren: () => import('./pages/view-uploaded-file/view-uploaded-file.module').then(m => m.ViewUploadedFileModule),
    canActivate: [MsalGuard],
    data: {
      breadcrumb: [
        {
          label: 'ETL Configuration',
          isLink: true,
          url: ''
        },
        {
          label: 'Configuration Management',
          isLink: true,
          url: ''
        },
        {
          label: 'Integration Setup',
          isLink: false,
          url: 'integration-setup'
        },
        {
          label: 'View Uploaded File',
          isLink: true,
          url: ''
        }
      ],
    }
  },
  {
    path: 'scope-master',
    loadChildren: () => import('./pages/useradmin/scope-master/scope-master.module').then(m => m.ScopeMasterModule),
    canActivate: [MsalGuard],
    data: {
      breadcrumb: [
        {
          label: 'User Admin',
          isLink: true,
          url: ''
        },
        {
          label: 'Scope Master',
          isLink: false,
          url: 'scope-master'
        }
      ],
    }
  },
  {
    path: 'role-master',
    loadChildren: () => import('./pages/useradmin/role-master/role-master.module').then(m => m.RoleMasterModule),
    canActivate: [MsalGuard],
    data: {
      breadcrumb: [
        {
          label: 'User Admin',
          isLink: true,
          url: ''
        },
        {
          label: 'Roles',
          isLink: false,
          url: 'role-master'
        }
      ],
    }
  },
  {
    path: 'role-permission',
    loadChildren: () => import('./pages/useradmin/role-permission/role-permission.module').then(m => m.RolePermissionModule),
    canActivate: [MsalGuard],
    data: {
      breadcrumb: [
        {
          label: 'User Admin',
          isLink: true,
          url: ''
        },
        {
          label: 'Roles',
          isLink: false,
          url: 'role-master'
        },
        {
          label: 'Permissions',
          isLink: false,
          url: 'role-permission'
        }
      ],
    }
  },
  {
    path: 'user-master',
    loadChildren: () => import('./pages/useradmin/user-master/user-master.module').then(m => m.UserMasterModule),
    canActivate: [MsalGuard],
    data: {
      breadcrumb: [
        {
          label: 'User Admin',
          isLink: true,
          url: ''
        },
        {
          label: 'User Profiles',
          isLink: false,
          url: 'user-master'
        }
      ],
    }
  },
  {
    path: 'user-role',
    loadChildren: () => import('./pages/useradmin/user-role/user-role.module').then(m => m.UserRoleModule),
    canActivate: [MsalGuard]
  },
  {
    path: 'user-profile',
    loadChildren: () => import('./pages/useradmin/user-profile/user-profile.module').then(m => m.UserProfileModule),
    canActivate: [MsalGuard],
    data: {
      breadcrumb: [
        {
          label: 'User Admin',
          isLink: true,
          url: ''
        },
        {
          label: 'User Profiles',
          isLink: false,
          url: 'user-master'
        },
        {
          label: 'View User Details',
          isLink: false,
          url: 'user-profile'
        }
      ],
    }
  },
  {
    path: 'roles-and-permission',
    loadChildren: () => import('./pages/common/roles-and-permission/roles-and-permission.module').then(m => m.RolesAndPermissionModule),
    canActivate: [MsalGuard]
  },
  {
    path: 'user-management',
    loadChildren: () => import('./pages/common/user-management/user-management.module').then(m => m.UserManagementModule),
    canActivate: [MsalGuard]
  },
  {
    path: 'validation-rule',
    loadChildren: () => import('./pages/common/validation-rule/validation-rule.module').then(m => m.ValidationRuleModule),
    canActivate: [MsalGuard]
  },
  {
    path: 'integration-monitoring2',
    loadChildren: () => import('./pages/common/integration-monitoring/integration-monitoring.module').then(m => m.IntegrationMonitoringModule),
    canActivate: [MsalGuard]
  },
  {
    path: 'integration-monitoring',
    loadChildren: () => import('./pages/monitoring/integration-monitoring/integration-monitoring.module').then(m => m.IntegrationMonitoringModule),
    canActivate: [MsalGuard],
    data: {
      breadcrumb: [
        {
          label: 'Integration Monitoring',
          isLink: false,
          url: 'integration-monitoring'
        }
      ],
    }
  },
  {
    path: 'transaction-view',
    loadChildren: () => import('./pages/monitoring/transaction-view/transaction-view.module').then(m => m.TransactionViewModule),
    canActivate: [MsalGuard]
  },
  {
    path: 'balancing-view',
    loadChildren: () => import('./pages/monitoring/balancing-view/balancing-view.module').then(m => m.BalancingViewModule),
    canActivate: [MsalGuard],
    data: {
      breadcrumb: [
        {
          label: 'Integration Monitoring',
          isLink: false,
          url: 'integration-monitoring'
        },
        {
          label: 'Balancing View',
          isLink: false,
          url: 'balancing-view'
        }
      ],
    }
  },
  {
    path: 'error-view',
    loadChildren: () => import('./pages/monitoring/error-view/error-view.module').then(m => m.ErrorViewModule),
    canActivate: [MsalGuard],
    data: {
      breadcrumb: [
        {
          label: 'Integration Monitoring',
          isLink: false,
          url: 'integration-monitoring'
        },
        {
          label: 'Error View',
          isLink: false,
          url: 'error-view'
        }
      ],
    }
  },
  {
    path: 'integration-setup-master',
    loadChildren: () => import('./pages/common/integration-setup/integration-setup.module').then(m => m.IntegrationSetupModule),
    canActivate: [MsalGuard]
  },
  {
    path: 'custom-integration-monitoring',
    loadChildren: () => import('./pages/common/custom-integration-monitoring/custom-integration-monitoring.module').then(m => m.CustomIntegrationMonitoringModule),
    canActivate: [MsalGuard]
  },
  {
    path: 'integration-master',
    loadChildren: () => import('./pages/common/integration-master/integration-master.module').then(m => m.IntegrationMasterModule),
    canActivate: [MsalGuard]
  },
  {
    path: 'integration-error',
    loadChildren: () => import('./pages/common/integration-error/integration-error.module').then(m => m.IntegrationErrorModule),
    canActivate: [MsalGuard]
  },
  {
    path: 'notifications',
    loadChildren: () => import('./pages/notifications/notification/notification.module').then(m => m.NotificationModule),
    canActivate: [MsalGuard],
    data: {
      breadcrumb: [
        {
          label: 'Error Notification',
          isLink: false,
          url: 'notifications'
        }
      ],
    }
  },
  {
    path: 'notification-details',
    loadChildren: () => import('./pages/notifications/notification-details/notification-details.module').then(m => m.NotificationDetailsModule),
    canActivate: [MsalGuard],
    data: {
      breadcrumb: [
        {
          label: 'Error Notification',
          isLink: false,
          url: 'notifications'
        },
        {
          label: 'Notification Details',
          isLink: true,
          url: 'notification-details'
        }
      ],
    }
  },
  {
    path: 'integration-user-mapping2',
    loadChildren: () => import('./pages/common/integration-user-mapping/integration-user-mapping.module').then(m => m.IntegrationUserMapping2Module),
    canActivate: [MsalGuard]
  },
  {
    path: 'integration-user-mapping',
    loadChildren: () => import('./pages/integration-user-mapping/integration-user-mapping.module').then(m => m.IntegrationUserMappingModule),
    canActivate: [MsalGuard]
  },
  {
    path: 'integration-error-view',
    loadChildren: () => import('./pages/common/error-view/error-view.module').then(m => m.ErrorViewModule),
    canActivate: [MsalGuard]
  },
  {
    path: 'custom-balancing-view',
    loadChildren: () => import('./pages/common/custom-balancing-view/custom-balancing-view.module').then(m => m.CustomBalancingViewModule),
    canActivate: [MsalGuard]
  },
  {
    path: 'hfm-mapping',
    loadChildren: () => import('./pages/common/hfm-mapping/hfm-mapping.module').then(m => m.HfmMappingModule),
    canActivate: [MsalGuard]
  },
  {
    path: 'mimo-configuration',
    loadChildren: () => import('./pages/common/mimo-configuration/mimo-configuration.module').then(m => m.MimoConfigurationModule),
    canActivate: [MsalGuard]
  },
  {
    path: 'banking-integration/bank-statement',
    loadChildren: () => import('./pages/banking-integration/bank-statement-upload/bank-statement-upload.module').then(m => m.BankStatementUploadModule),
    canActivate: [MsalGuard],
    data: {
      breadcrumb: [
        {
          label: 'Banking Integration',
          isLink: true,
          url: ''
        },
        {
          label: 'Upload Bank Statement',
          isLink: false,
          url: 'banking-integration/bank-statement'
        }
      ],
    }
  },
  {
    path: 'banking-integration/all-uploaded-file',
    loadChildren: () => import('./pages/banking-integration/bank-statement-file-upload/bank-statement-file-upload.module').then(m => m.BankStatementFileUploadModule),
    canActivate: [MsalGuard],
    data: {
      breadcrumb: [
        {
          label: 'Banking Integration',
          isLink: true,
          url: ''
        },
        {
          label: 'Upload Bank Statement',
          isLink: false,
          url: 'banking-integration/bank-statement'
        },
        {
          label: 'All Upload Bank Statement',
          isLink: true,
          url: 'banking-integration/all-uploaded-file'
        }
      ],
    }
  },
  {
    path: 'hfm-all-submission',
    loadChildren: () => import('./pages/hfm-submissions/hfm-all-submission/hfm-all-submission.module').then(m => m.HfmAllSubmissionModule),
    canActivate: [MsalGuard],
    data: {
      breadcrumb: [
        {
          label: 'HFM',
          isLink: true,
          url: 'hfm-all-submission'
        }
      ],
    }
  },
  {
    path: 'hfm-submit',
    loadChildren: () => import('./pages/hfm-submissions/hfm-submit/hfm-submit.module').then(m => m.HfmSubmitModule),
    canActivate: [MsalGuard],
    data: {
      breadcrumb: [
        {
          label: 'HFM',
          isLink: false,
          url: 'hfm-all-submission'
        },
        {
          label: 'HFM Submission',
          isLink: true,
          url: 'hfm-submit'
        }
      ],
    }
  },
  {
    path: 'hfm-resubmit',
    loadChildren: () => import('./pages/hfm-submissions/hfm-resubmission/hfm-resubmission.module').then(m => m.HfmResubmissionModule),
    canActivate: [MsalGuard],
    data: {
      breadcrumb: [
        {
          label: 'HFM',
          isLink: false,
          url: 'hfm-all-submission'
        },
        {
          label: 'FDM Status Update',
          isLink: true,
          url: 'hfm-resubmit'
        }
      ],
    }
  },
  {
    path: 'hfm-export',
    loadChildren: () => import('./pages/hfm-submissions/hfm-export/hfm-export.module').then(m => m.HfmExportModule),
    canActivate: [MsalGuard],
    data: {
      breadcrumb: [
        {
          label: 'HFM',
          isLink: false,
          url: 'hfm-all-submission'
        },
        {
          label: 'Export Data',
          isLink: true,
          url: 'hfm-export'
        }
      ],
    }
  },
  {
    path: 'reconcilation',
    loadChildren: () => import('./pages/hfm-submissions/reconcilation/reconcilation.module').then(m => m.ReconcilationModule),
    canActivate: [MsalGuard],
    data: {
      breadcrumb: [
        {
          label: 'HFM',
          isLink: false,
          url: 'hfm-all-submission'
        },
        {
          label: 'Reconciliation',
          isLink: true,
          url: 'reconcilation'
        }
      ],
    }
  },
  {
    path: 'onestream-all-submission',
    loadChildren: () => import('./pages/onestream-submissions/hfm-all-submission/hfm-all-submission.module').then(m => m.HfmAllSubmissionModule),
    canActivate: [MsalGuard],
    data: {
      breadcrumb: [
        {
          label: 'Onestream',
          isLink: true,
          url: 'onestream-all-submission'
        }
      ],
    }
  },
  {
    path: 'onestream-submit',
    loadChildren: () => import('./pages/onestream-submissions/hfm-submit/hfm-submit.module').then(m => m.HfmSubmitModule),
    canActivate: [MsalGuard],
    data: {
      breadcrumb: [
        {
          label: 'Onestream',
          isLink: false,
          url: 'onestream-all-submission'
        },
        {
          label: 'Onestream Submission',
          isLink: true,
          url: 'onestream-submit'
        }
      ],
    }
  },
  {
    path: 'onestream-resubmit',
    loadChildren: () => import('./pages/onestream-submissions/hfm-resubmission/hfm-resubmission.module').then(m => m.HfmResubmissionModule),
    canActivate: [MsalGuard],
    data: {
      breadcrumb: [
        {
          label: 'Onestream',
          isLink: false,
          url: 'onestream-all-submission'
        },
        {
          label: 'Onestream Status Update',
          isLink: true,
          url: 'onestream-resubmit'
        }
      ],
    }
  },
  {
    path: 'onestream-export',
    loadChildren: () => import('./pages/onestream-submissions/hfm-export/hfm-export.module').then(m => m.HfmExportModule),
    canActivate: [MsalGuard],
    data: {
      breadcrumb: [
        {
          label: 'Onestream',
          isLink: false,
          url: 'onestream-all-submission'
        },
        {
          label: 'Export Data',
          isLink: true,
          url: 'onestream-export'
        }
      ],
    }
  },
  {
    path: 'onestream-reconcilation',
    loadChildren: () => import('./pages/onestream-submissions/reconcilation/reconcilation.module').then(m => m.ReconcilationModule),
    canActivate: [MsalGuard],
    data: {
      breadcrumb: [
        {
          label: 'Onestream',
          isLink: false,
          url: 'onestream-all-submission'
        },
        {
          label: 'Reconciliation',
          isLink: true,
          url: 'reconcilation'
        }
      ],
    }
  },
  {
    path: 'banking-integration/bank-details',
    loadChildren: () => import('./pages/banking-integration/bank-details/bank-details.module').then(m => m.BankDetailsModule),
    canActivate: [MsalGuard],
    data: {
      breadcrumb: [
        {
          label: 'Banking Integration',
          isLink: true,
          url: ''
        },
        {
          label: 'Configuration',
          isLink: true,
          url: ''
        },
        {
          label: 'Bank Mapping',
          isLink: true,
          url: ''
        }
      ],
    }
  },
  {
    path: 'banking-integration/configuration',
    loadChildren: () => import('./pages/banking-integration/configuration/configuration.module').then(m => m.ConfigurationModule),
    canActivate: [MsalGuard],
    data: {
      breadcrumb: [
        {
          label: 'Banking Integration',
          isLink: true,
          url: ''
        },
        {
          label: 'Configuration',
          isLink: true,
          url: ''
        },
        {
          label: 'Bank Connection',
          isLink: true,
          url: ''
        }
      ],
    }
  },
  {
    path: 'banking-integration/configuration/create-bank-connection',
    loadChildren: () => import('./pages/banking-integration/create-bank-connection/create-bank-connection.module').then(m => m.CreateBankConnectionModule),
    canActivate: [MsalGuard],
    data: {
      breadcrumb: [
        {
          label: 'Banking Integration',
          isLink: true,
          url: ''
        },
        {
          label: 'Configuration',
          isLink: true,
          url: ''
        },
        {
          label: 'Bank Connection',
          isLink: false,
          url: 'banking-integration/configuration'
        },
        {
          label: 'Create Bank Connection',
          isLink: true,
          url: ''
        }
      ],
    }
  },
  {
    path: 'banking-integration/account-statements',
    loadChildren: () => import('./pages/banking-integration/account-statements/account-statements.module').then(m => m.AccountStatementsModule),
    canActivate: [MsalGuard],
    data: {
      breadcrumb: [
        {
          label: 'Banking Integration',
          isLink: true,
          url: ''
        },
        {
          label: 'Finance Report',
          isLink: true,
          url: ''
        },
        {
          label: 'Account Statement',
          isLink: true,
          url: ''
        }
      ],
    }
  },
  {
    path: 'banking-integration/bank-operation',
    loadChildren: () => import('./pages/banking-integration/bank-operation/bank-operation.module').then(m => m.BankOperationModule),
    canActivate: [MsalGuard],
    data: {
      breadcrumb: [
        {
          label: 'Banking Integration',
          isLink: true,
          url: ''
        },
        {
          label: 'Master',
          isLink: true,
          url: ''
        },
        {
          label: 'Bank Operation',
          isLink: true,
          url: ''
        }
      ],
    }
  },
  {
    path: 'banking-integration/collections',
    loadChildren: () => import('./pages/banking-integration/collections/collections.module').then(m => m.CollectionsModule),
    canActivate: [MsalGuard],
    data: {
      breadcrumb: [
        {
          label: 'Banking Integration',
          isLink: true,
          url: ''
        },
        {
          label: 'Finance Report',
          isLink: true,
          url: ''
        },
        {
          label: 'Collection',
          isLink: true,
          url: ''
        }
      ],
    }
  },
  ,
  {
    path: 'banking-integration/disbursements',
    loadChildren: () => import('./pages/banking-integration/disbursements/disbursements.module').then(m => m.DisbursementsModule),
    canActivate: [MsalGuard],
    data: {
      breadcrumb: [
        {
          label: 'Banking Integration',
          isLink: true,
          url: ''
        },
        {
          label: 'Finance Report',
          isLink: true,
          url: ''
        },
        {
          label: 'Disbursement',
          isLink: true,
          url: ''
        }
      ],
    }
  },
  {
    path: 'banking-integration/d365-status',
    loadChildren: () => import('./pages/banking-integration/d365-status/d365-status.module').then(m => m.D365StatusModule),
    canActivate: [MsalGuard],
    data: {
      breadcrumb: [
        {
          label: 'Banking Integration',
          isLink: true,
          url: ''
        },
        {
          label: 'Master',
          isLink: true,
          url: ''
        },
        {
          label: 'D365 Status',
          isLink: true,
          url: ''
        }
      ],
    }
  },
  {
    path: 'banking-integration/bank-operation-status',
    loadChildren: () => import('./pages/banking-integration/bank-operation-status/bank-operation-status.module').then(m => m.BankOperationStatusModule),
    canActivate: [MsalGuard],
    data: {
      breadcrumb: [
        {
          label: 'Banking Integration',
          isLink: true,
          url: ''
        },
        {
          label: 'Master',
          isLink: true,
          url: ''
        },
        {
          label: 'Bank Operation Status',
          isLink: true,
          url: ''
        }
      ],
    }
  },
  {
    path: 'banking-integration/bank',
    loadChildren: () => import('./pages/banking-integration/bank/bank.module').then(m => m.BankModule),
    canActivate: [MsalGuard],
    data: {
      breadcrumb: [
        {
          label: 'Banking Integration',
          isLink: true,
          url: ''
        },
        {
          label: 'Master',
          isLink: true,
          url: ''
        },
        {
          label: 'Bank',
          isLink: true,
          url: ''
        }
      ],
    }
  },
  {
    path: 'banking-integration/legal-entity',
    loadChildren: () => import('./pages/banking-integration/legal-entity/legal-entity.module').then(m => m.LegalEntityModule),
    canActivate: [MsalGuard],
    data: {
      breadcrumb: [
        {
          label: 'Banking Integration',
          isLink: true,
          url: ''
        },
        {
          label: 'Master',
          isLink: true,
          url: ''
        },
        {
          label: 'Legal Entity',
          isLink: true,
          url: ''
        }
      ],
    }
  },
  {
    path: 'banking-integration/bank-program',
    loadChildren: () => import('./pages/banking-integration/bank-program/bank-program.module').then(m => m.BankProgramModule),
    canActivate: [MsalGuard],
    data: {
      breadcrumb: [
        {
          label: 'Banking Integration',
          isLink: true,
          url: ''
        },
        {
          label: 'Master',
          isLink: true,
          url: ''
        },
        {
          label: 'Programme',
          isLink: true,
          url: ''
        }
      ],
    }
  },
  {
    path: 'banking-integration/bank-operation-master',
    loadChildren: () => import('./pages/banking-integration/bank-operation-master/bank-operation-master.module').then(m => m.BankOperationMasterModule),
    canActivate: [MsalGuard],
    data: {
      breadcrumb: [
        {
          label: 'Banking Integration',
          isLink: true,
          url: ''
        },
        {
          label: 'Master',
          isLink: true,
          url: ''
        },
        {
          label: 'Bank Operation',
          isLink: true,
          url: ''
        }
      ],
    }
  },
  {
    path: 'banking-integration/message-format-master',
    loadChildren: () => import('./pages/banking-integration/message-format-master/message-format-master.module').then(m => m.MessageFormatMasterModule),
    canActivate: [MsalGuard],
    data: {
      breadcrumb: [
        {
          label: 'Banking Integration',
          isLink: true,
          url: ''
        },
        {
          label: 'Master',
          isLink: true,
          url: ''
        },
        {
          label: 'Message Format',
          isLink: true,
          url: ''
        }
      ],
    }
  },
  {
    path: 'banking-integration/connectivity-protocol-master',
    loadChildren: () => import('./pages/banking-integration/connectivity-protocol-master/connectivity-protocol-master.module').then(m => m.ConnectivityProtocolMasterModule),
    canActivate: [MsalGuard],
    data: {
      breadcrumb: [
        {
          label: 'Banking Integration',
          isLink: true,
          url: ''
        },
        {
          label: 'Master',
          isLink: true,
          url: ''
        },
        {
          label: 'Connectivity Protocol',
          isLink: true,
          url: ''
        }
      ],
    }
  },
  {
    path: 'banking-integration/bank-reason-code',
    loadChildren: () => import('./pages/banking-integration/bank-reason-code/bank-reason-code.module').then(m => m.BankReasonCodeModule),
    canActivate: [MsalGuard],
    data: {
      breadcrumb: [
        {
          label: 'Banking Integration',
          isLink: true,
          url: ''
        },
        {
          label: 'Master',
          isLink: true,
          url: ''
        },
        {
          label: 'Bank Reason Code',
          isLink: true,
          url: ''
        }
      ],
    }
  },
  {
    path: '**', redirectTo: '/pagenotfound'
  },
];


@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, useHash: true })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }

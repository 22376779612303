import { Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { DialogAuthorizationErrorComponent } from './components/dialog-authorization-error/dialog-authorization-error.component';
import { UserService } from './services/user/user.service';
import { SIDEBARS } from './utils/sidebar';
import { SettingService } from './services/setting/setting.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {

  private readonly _destroying$ = new Subject<void>();

  data: any[];
  permissions: any[] = [];

  
  constructor(
    private router: Router,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private dialog: MatDialog,
    private userService: UserService,
    private settingService: SettingService
  ) {}

  ngOnInit(): void {
    // this.checkPermission();

        //After successful login
        this.msalBroadcastService.msalSubject$
        .pipe(
          filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
          takeUntil(this._destroying$)
        )
        .subscribe((result: EventMessage) => {
          localStorage.setItem("accessToken", result.payload["accessToken"]);
          localStorage.setItem("userName", result.payload["account"]["username"]);

          this.settingService.getAppSetting(environment.envName).subscribe((res: any) => {
            sessionStorage.setItem("accountName", res.accountName);
            sessionStorage.setItem("sasToken", res.sasToken);
            sessionStorage.setItem("secretName", res.secretName);
            sessionStorage.setItem("secretCode", res.secretCode);
            sessionStorage.setItem("bankAccountName", res.bankAccountName);
            sessionStorage.setItem("bankSasToken", res.bankSasToken);
            sessionStorage.setItem("bankSecretName", res.bankSecretName);
            sessionStorage.setItem("bankSecretCode", res.bankSecretCode);
            sessionStorage.setItem("bankContainerName", res.bankContainerName);

            this.settingService.getSasToken(res.secretName, res.secretCode).subscribe((token: string) => {
              sessionStorage.setItem("sasToken", token);
            })
            this.settingService.getSasToken(res.bankSecretName, res.bankSecretCode).subscribe((token: string) => {
              sessionStorage.setItem("bankSasToken", token);
            })

          })

          this.userService.getUserProfilePermission(result.payload["account"]["username"]).subscribe((response)=>{
            if(response.isActive){
              localStorage.setItem("userId", response.id);

              this.data = response.permissionList;
  
              this.data.forEach((p)=>{
                if(!p.parentName){
                  let permissionData = {
                    name: p.name,
                    displayName: p.displayName,
                    isGranted: p.isGranted,
                    modules: []
                  }
                  this.permissions.push(permissionData);
                }
                if(p.parentName){
                  let index = this.permissions.findIndex(pi => pi.name == p.parentName);
                  if(index >= 0){
                    let permissionData = {
                      name: p.name,
                      displayName: p.displayName,
                      isGranted: p.isGranted,
                      modules: []
                    }
                    this.permissions[index].modules.push(permissionData);
                  }
        
                }
              })
  
              this.permissions.forEach(pm => {
                if(pm.modules.length > 0){
                  pm.modules.forEach(pmd => {
                    if(this.data.find(p => p.parentName == pmd.name)){
                      let pmdata = this.data.filter(p => p.parentName == pmd.name);
                      pmdata.forEach(pmd2 => {
                        let permissionData = {
                          name: pmd2.name,
                          displayName: pmd2.displayName,
                          isGranted: pmd2.isGranted,
                          modules: []
                        }
                        pmd.modules.push(permissionData);
                      })
        
        
                    }
                  })
                }
              })
  
  
              this.permissions.forEach(pm1 => {
                if(pm1.modules.length > 0){
                  pm1.modules.forEach(pm2 => {
                    if(pm2.isGranted){
                      pm1.isGranted = true;
                    }
                    pm2.modules.forEach(pm3 => {
                      if(pm3.isGranted){
                        pm2.isGranted = true;
                        pm1.isGranted = true;
                      }
                      if(this.data.find(p => p.parentName == pm3.name)){
                        let pmdata = this.data.filter(p => p.parentName == pm3.name);
                        pmdata.forEach(pmd2 => {
                          let permissionData = {
                            name: pmd2.name,
                            displayName: pmd2.displayName,
                            isGranted: pmd2.isGranted,
                            modules: []
                          }
  
                          if(pmd2.isGranted){
                            pm3.isGranted = true;
                            pm2.isGranted = true;
                            pm1.isGranted = true;
                          }
                          pm3.modules.push(permissionData);
                        })
          
          
                      }
                    })
        
                  })
                }
              });

              localStorage.setItem("accessToken", result.payload["accessToken"]);
              localStorage.setItem("permissions", JSON.stringify(this.permissions));
              const payload = result.payload as AuthenticationResult;
              this.authService.instance.setActiveAccount(payload.account);
              
            }else{
              this.showAuthorizationError();
            }


          }, (error)=>{
            if(error){
              this.showAuthorizationError();
            }
          })
          


        });
  }

  showAuthorizationError(){
    const dialogRef = this.dialog.open(DialogAuthorizationErrorComponent, {
      data: {},
    });

    dialogRef.afterClosed().subscribe(result => {
      localStorage.clear();
      sessionStorage.clear();
      this.authService.logout();

    });
  }

}

import { environment } from "src/environments/environment";

export class Urls {
  public static baseUrl = environment.apiBaseUrl;
  public static bankingBaseUrl = environment.bankingApiBaseUrl;
  
  public static userScopeUrl = Urls.baseUrl + "user/api/identity/UserScope";
  public static userScopeUpdateBulkUrl = Urls.baseUrl + "user/api/identity/UserScope/UpdateBulk";
  public static userEntityUrl = Urls.baseUrl + "user/api/identity/UserEntity";
  public static omUsersUrl = Urls.baseUrl + "user/api/OM/User";
  public static omUsersByRoleUrl = Urls.baseUrl + "user/api/OM/User/by-role";
  public static omUsersByScopeUrl = Urls.baseUrl + "user/api/OM/User/by-scope";
  public static usersUrl = Urls.baseUrl + "user/api/identity/users";
  public static userProfilePermissionUrl = Urls.baseUrl + "user/api/identity/UserProfile/pemission";
  public static userProfileUrl = Urls.baseUrl + "user/api/identity/UserProfile";
  public static rolesUrl = Urls.baseUrl + "user/api/identity/roles";
  public static permissionsUrl = Urls.baseUrl + "user/api/permission-management/permissions";

  public static monitoringUrl = Urls.baseUrl + "monitoring/api/integration-monitoring"
  public static monitoringBalancingUrl = Urls.baseUrl + "monitoring/api/balancing"
  public static monitoringErrorUrl = Urls.baseUrl + "monitoring/api/validationOutput"
  
  public static keyVaultUrl =  Urls.baseUrl + "keyvault";


  public static bankStatementUrl = Urls.bankingBaseUrl + "bank-integration/api/Bank";

  // HFM Submission
  public static hfmLegalEntity =  Urls.baseUrl + "monitoring/api/hfm/legalentities";
  public static OSLegalEntity =  Urls.baseUrl + "monitoring/api/hfm/OSlegalentities";
  public static hfmMonth =  Urls.baseUrl + "monitoring/api/hfm/months";
  public static OSMonth =  Urls.baseUrl + "monitoring/api/hfm/OSmonths";
  public static hfmYear =  Urls.baseUrl + "monitoring/api/hfm/years";
  public static OSYear =  Urls.baseUrl + "monitoring/api/hfm/OSyears";
  public static hfmInProgress =  Urls.baseUrl + "monitoring/api/hfm/legalentitiesinprogress";
  public static OSInProgress =  Urls.baseUrl + "monitoring/api/hfm/OSlegalentitiesinprogress";
  public static hfmVersion = Urls.baseUrl + "monitoring/api/hfm/hfmfdmversion";
  public static OShfmVersion = Urls.baseUrl + "monitoring/api/hfm/OShfmfdmversion";
  public static hfmLayer = Urls.baseUrl + "monitoring/api/hfm/postingLayer";
  public static OSLayer = Urls.baseUrl + "monitoring/api/hfm/OSpostingLayer";
  public static hfmTransactionType = Urls.baseUrl + "monitoring/api/hfm/transactiontype";
  public static fdmStatus = Urls.baseUrl + "monitoring/api/hfm/fdmstatus";
  public static OSfdmStatus = Urls.baseUrl + "monitoring/api/hfm/OSfdmstatus";
  public static hfmTofdmStatusUpdate = Urls.baseUrl + "monitoring/api/hfm/hfmtofdmstatusupdate";
  public static OShfmTofdmStatusUpdate = Urls.baseUrl + "monitoring/api/hfm/OShfmtofdmstatusupdate";
  public static hfmExport = Urls.baseUrl + "monitoring/api/hfm/hfmfdmexportdata";
  public static OShfmExport = Urls.baseUrl + "monitoring/api/hfm/OShfmfdmexportdata";
  public static reconciliationExport = Urls.baseUrl + "monitoring/api/hfm/reconciliation";
  public static OSreconciliationExport = Urls.baseUrl + "monitoring/api/hfm/OSreconciliation";


  public static hfmTofdm = Urls.baseUrl + "monitoring/api/hfm/hfmtofdm";
  public static OShfmTofdm = Urls.baseUrl + "monitoring/api/hfm/OShfmtofdm";
  public static OShfmSubmission = Urls.baseUrl + "monitoring/api/hfm/OShfmSubmission1";


  //MIMO Config
  public static mimoLegalEntityAccount = Urls.bankingBaseUrl + "bank-integration/api/LegalEntityAccount/BankAccounts";
  public static bankAccountActiveOrDeactive = Urls.bankingBaseUrl + "bank-integration/api/LegalEntityAccount/BankAccountActiveOrDeactive";
  public static mimoBankConnection = Urls.bankingBaseUrl + "bank-integration/api/BankConnection/List";
  public static mimoAllBankOperations = Urls.bankingBaseUrl + "bank-integration/api/BankOperation/List";
  public static mimoAllConnectivityProtocol = Urls.bankingBaseUrl + "bank-integration/api/ConnectivityProtocol/List";
  public static mimoAllMessageFormat = Urls.bankingBaseUrl + "bank-integration/api/MessageFormat/List";
  public static mimoAllLegalEntityBankMapping = Urls.bankingBaseUrl + "bank-integration/api/LegalEntityBankMapping/All";
  public static mimoCreateBankConnection = Urls.bankingBaseUrl + "bank-integration/api/BankConnection/Add";
  public static mimoBankConnectionDetails = Urls.bankingBaseUrl + "bank-integration/api/BankConnection/Detail";
  public static mimoAllAuthType = Urls.bankingBaseUrl + "bank-integration/api/AuthType/List";
  public static mimoBankConnectionUpdate = Urls.bankingBaseUrl + "bank-integration/api/BankConnection/Update";
  public static searchBankConnection = Urls.bankingBaseUrl + "bank-integration/api/BankConnection/SearchBankConnection";
  public static connectionParameter = Urls.bankingBaseUrl + "bank-integration/api/BankConnection/ConnectionParameter";
  public static searchBankOperations = Urls.bankingBaseUrl + "bank-integration/api/BankOperation/BankOperations";
  public static bankOperationActiveOrDeactive = Urls.bankingBaseUrl + "bank-integration/api/BankOperation/BankOperationActiveOrDeactive";
  public static bankCollections = Urls.bankingBaseUrl + "bank-integration/api/Bank/Collections";
  public static bankDisbursements = Urls.bankingBaseUrl + "bank-integration/api/Bank/Disbursements";
  public static bankStatements = Urls.bankingBaseUrl + "bank-integration/api/Bank/Statements";
  public static bankD365Status = Urls.bankingBaseUrl + "bank-integration/api/D365SyncStatus/List";
  public static bankAddD365SyncStatus = Urls.bankingBaseUrl + 'bank-integration/api/D365SyncStatus/Add';
  public static bankUpdateD365syncstatus = Urls.bankingBaseUrl + "bank-integration/api/D365SyncStatus/Update";
  public static bankOperationtatus = Urls.bankingBaseUrl + "bank-integration/api/BankOperationStatus/List";
  public static bankAddOperationtatus = Urls.bankingBaseUrl + "bank-integration/api/BankOperationStatus/Add";
  public static bankUpdateOperationtatus = Urls.bankingBaseUrl + "bank-integration/api/BankOperationStatus/Update";
  public static bankConnectionExportToExcel = Urls.bankingBaseUrl + "bank-integration/api/BankConnection/ExportToExcel";
  public static bankCollectionExportToExcel = Urls.bankingBaseUrl + "bank-integration/api/Bank/ExportToExcelCollections";
  public static bankDisbursementExportToExcel = Urls.bankingBaseUrl + "bank-integration/api/Bank/ExportToExcelDisbursement";
  public static bankStatementExportToExcel = Urls.bankingBaseUrl + "bank-integration/api/Bank/ExportToExcelStatement";
  public static bankOperationExportToExcel = Urls.bankingBaseUrl + "bank-integration/api/BankOperation/ExportToExcelBankOperations";
  public static bankLegalEntityAccountExportToExcel = Urls.bankingBaseUrl + "bank-integration/api/LegalEntityAccount/ExportToExcel";
  public static bankOperationStatusExportToExcel = Urls.bankingBaseUrl + "bank-integration/api/BankOperationStatus/ExportToExcel";
  public static bankD365StatusExportToExcel = Urls.bankingBaseUrl + "bank-integration/api/D365SyncStatus/ExportToExcel";
  public static bankBanks = Urls.bankingBaseUrl + "bank-integration/api/Bank/Banks";
  public static bankAddBank = Urls.bankingBaseUrl + 'bank-integration/api/Bank/AddNewBank';
  public static bankUpdateBank = Urls.bankingBaseUrl + 'bank-integration/api/Bank/UpdateBank';
  public static bankExportBank = Urls.bankingBaseUrl + 'bank-integration/api/Bank/ExportToExcelBank';
  public static bankLegalEntity = Urls.bankingBaseUrl + "bank-integration/api/LegalEntity/LegalEntities";
  public static bankPrograms = Urls.bankingBaseUrl + 'bank-integration/api/LegalEntity/Programs';
  public static bankAddLegalEntity = Urls.bankingBaseUrl + 'bank-integration/api/LegalEntity/Add';
  public static bankUpdateLegalEntity = Urls.bankingBaseUrl + 'bank-integration/api/LegalEntity/Update';
  public static bankExportLegalEntity = Urls.bankingBaseUrl + 'bank-integration/api/LegalEntity/ExportToExcel';
  public static bankAllProgram = Urls.bankingBaseUrl + 'bank-integration/api/Program/List';
  public static bankAddProgram = Urls.bankingBaseUrl + 'bank-integration/api/Program/Add';
  public static bankUpdateProgram = Urls.bankingBaseUrl + 'bank-integration/api/Program/Update';
  public static bankProgramExport = Urls.bankingBaseUrl + 'bank-integration/api/Program/ExportToExcel';
  public static bankAllOperationMaster = Urls.bankingBaseUrl + 'bank-integration/api/BankOperationMaster/List';
  public static bankAddUpdateOperationMaster = Urls.bankingBaseUrl + 'bank-integration/api/BankOperationMaster';
  public static bankAllOperationMasterExport = Urls.bankingBaseUrl + 'bank-integration/api/BankOperationMaster/ExportToExcel';
  public static bankAllMessageFormatMaster = Urls.bankingBaseUrl + 'bank-integration/api/MessageFormat/ListPagination';
  public static bankAddUpdateMessageFormat = Urls.bankingBaseUrl + 'bank-integration/api/MessageFormat';
  public static bankMessageFormatMasterExport = Urls.bankingBaseUrl + 'bank-integration/api/MessageFormat/ExportToExcel';
  public static bankAllConnectivityProtocolMaster = Urls.bankingBaseUrl + 'bank-integration/api/ConnectivityProtocol/ListPagination';
  public static bankAddUpdateConnectivityProtocolMaster = Urls.bankingBaseUrl + 'bank-integration/api/ConnectivityProtocol';
  public static bankConnectivityProtocolMasterExport = Urls.bankingBaseUrl + 'bank-integration/api/ConnectivityProtocol/ExportToExcel';
  public static bankAllBankReasonCodeMaster = Urls.bankingBaseUrl + 'bank-integration/api/BankReasonCode/List';
  public static bankAddUpdateBankReasonCodeMaster = Urls.bankingBaseUrl + 'bank-integration/api/BankReasonCode';
  public static bankBankReasonCodeMasterExportToExcel = Urls.bankingBaseUrl + 'bank-integration/api/BankReasonCode/ExportToExcel';
  public static bankGetAllCountry = Urls.bankingBaseUrl + 'bank-integration/api/Bank/GetAllCountry';
  public static bankGetAllBankByCountry = Urls.bankingBaseUrl + 'bank-integration/api/Bank/GetAllBankByCountry';
  public static bankGetAllLegalEntityByBank = Urls.bankingBaseUrl + 'bank-integration/api/Bank/GetAllLegalEntityByBank';
  public static bankAddBankMapping = Urls.bankingBaseUrl + 'bank-integration/api/LegalEntityAccount/AddBankMapping';
  public static bankUpdateBankMapping = Urls.bankingBaseUrl + 'bank-integration/api/LegalEntityAccount/UpdateBankMapping';
  public static mimoAuditTrail = Urls.bankingBaseUrl + 'bank-integration/api/ActionLogs/GetLogsByObjectName';
  public static mimoStatementSummary = Urls.bankingBaseUrl + 'bank-integration/api/Bank/GetStatementTransactionBySummaryId';
  public static mimoDisbursementSummary = Urls.bankingBaseUrl + 'bank-integration/api/Bank/GetDisbursementTransactionById';
  public static bankLegalEntityList = Urls.bankingBaseUrl + 'bank-integration/api/LegalEntity/List';
  public static bankFileUploadList = Urls.bankingBaseUrl + 'bank-integration/api/FileUpload/List';
  //monitoring
  public static mappingExportUrl = Urls.baseUrl + "monitoring/api/dimensions"

  public static settingUrl = Urls.baseUrl + "monitoring/api/setting/om"
}

import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { DialogLogoutConfirmationComponent } from '../dialog-logout-confirmation/dialog-logout-confirmation.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
// import { parseI18nMeta } from '@angular/compiler/src/render3/view/i18n/meta';
import { PermissionService } from 'src/app/services/permission/permission.service';
import '../../../scripts/sidebar-menu.js';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit, OnChanges {

  isMenuActive: boolean = true;
  username="";
  permissions: any[] = [];
  routerUrl: Router;
  constructor(
    private router: Router,
    private dialog: MatDialog,
    private authService: MsalService,
    private permissionService: PermissionService
  ) {
    this.routerUrl = router
   }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  logout() {
    const dialogRef = this.dialog.open(DialogLogoutConfirmationComponent);

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      if(result){
        localStorage.clear();
        this.router.navigate(["/login"])
      }
    });
  }

  goTo(url: string){
    this.router.navigate([url]);
  }

  menuOpen(){
    if(!this.isMenuActive){
      this.isMenuActive = true;
    }else{
      this.isMenuActive = false;
    }
  }

  isSubMenuHeaderActive(name: string){
    if(name == 'dimension'){
      if(this.router.url == '/dimension/country'
      || this.router.url == '/dimension/country-cluster'
      || this.router.url == '/dimension/legal-entity'
      || this.router.url == '/dimension/line-of-business'
      || this.router.url == '/dimension/segment'
      || this.router.url == '/dimension/channel'
      || this.router.url == '/dimension/region'
      || this.router.url == '/dimension/area'
      || this.router.url == '/dimension/branch'
      || this.router.url == '/dimension/portfolio'
      || this.router.url == '/dimension/product'
      || this.router.url == '/dimension/department'
      || this.router.url == '/dimension/project'
      || this.router.url == '/dimension/intercompany'
      || this.router.url == '/dimension/pns'
      )
      {
        return true;
      }else{
        return false;
      }
    }
    if(name == 'chartOfAccount'){
      if(this.router.url == '/main-account'
      || this.router.url == '/main-account-category'
      )
      {
        return true;
      }else{
        return false;
      }

    }
    if(name == 'dimension-mapping'){
      if(this.router.url == '/mapping/lineofbusiness'
      || this.router.url == '/mapping/segment'
      || this.router.url == '/mapping/channel'
      || this.router.url == '/mapping/branch'
      || this.router.url == '/mapping/product'
      || this.router.url == '/mapping/department'
      || this.router.url == '/mapping/project'
      || this.router.url == '/mapping/intercompany'
      || this.router.url == '/mapping/pns'
      || this.router.url == '/mapping/main-account'
      ){
        return true;
      }else{
        return false;
      }
    }

    if(name == 'configuration-masters'){
      if(this.router.url == '/master/source-system'
      || this.router.url == '/master/integration-item'
      || this.router.url == '/master/source-data-category'
      || this.router.url == '/master/inbound-table-name'
      || this.router.url == '/master/outbound-table-name'
      || this.router.url == '/master/source-data-from'
      || this.router.url == '/master/validation-tier'
      || this.router.url == '/master/dvf-type'
      || this.router.url == '/master/validation-rule'
      || this.router.url == '/master/dt-type'
      || this.router.url == '/master/transformation-rule'
      ){
        return true;
      }else{
        return false;
      }
    }

    if(name == 'configuration-management'){
      if(this.router.url == '/integration-setup'
      || this.router.url == '/integration-review'
      || this.router.url == '/integration-review'
      ){
        return true;
      }else{
        return false;
      }
    }
  }

  isMenuHeaderActive(name: string){
    if(name == 'd365'){
      if(this.router.url == '/dimension/country'
      || this.router.url == '/dimension/country-cluster'
      || this.router.url == '/dimension/legal-entity'
      || this.router.url == '/dimension/line-of-business'
      || this.router.url == '/dimension/segment'
      || this.router.url == '/dimension/channel'
      || this.router.url == '/dimension/region'
      || this.router.url == '/dimension/area'
      || this.router.url == '/dimension/branch'
      || this.router.url == '/dimension/portfolio'
      || this.router.url == '/dimension/product'
      || this.router.url == '/dimension/department'
      || this.router.url == '/dimension/project'
      || this.router.url == '/dimension/intercompany'
      || this.router.url == '/dimension/pns'
      || this.router.url == '/main-account'
      || this.router.url == '/main-account-category'
      ){
        return true;
      }else{
        return false;
      }
    }
    if(name == 'mapping'){
      if(this.router.url == '/mapping/lineofbusiness'
      || this.router.url == '/mapping/segment'
      || this.router.url == '/mapping/channel'
      || this.router.url == '/mapping/branch'
      || this.router.url == '/mapping/product'
      || this.router.url == '/mapping/department'
      || this.router.url == '/mapping/project'
      || this.router.url == '/mapping/intercompany'
      || this.router.url == '/mapping/pns'
      || this.router.url == '/mapping/chart-of-account'

      ){
        return true;
      }else{
        return false;
      }
    }

    if(name == 'etl-configuration'){
      if(this.router.url == '/master/source-system'
      || this.router.url == '/master/integration-item'
      || this.router.url == '/master/source-data-category'
      || this.router.url == '/master/inbound-table-name'
      || this.router.url == '/master/outbound-table-name'
      || this.router.url == '/master/source-data-from'
      || this.router.url == '/master/validation-tier'
      || this.router.url == '/master/dvf-type'
      || this.router.url == '/master/validation-rule'
      || this.router.url == '/master/dt-type'
      || this.router.url == '/master/transformation-rule'
      || this.router.url == '/integration-setup'
      || this.router.url == '/integration-review'
      || this.router.url == '/integration-review'

      ){
        return true;
      }else{
        return false;
      }
    }
  }

  getUserDetails(){
    let activeAccount: any[] = this.authService.instance.getAllAccounts();
    if(activeAccount.length > 0){
      this.username = activeAccount[0].idTokenClaims.name;
    }
  }

  getPermission(level1, level2, level3){
    this.permissions = this.permissionService.getPermissions();
    if(this.permissions && this.permissions.length > 0){
      if(level3){
        if(this.permissions.find(p1 => p1.displayName == level1).modules.find(p2 => p2.displayName == level2).modules.find(p3 => p3.displayName == level3).isGranted){
          return true;
        }else{
          return false;
        }
      }else if(level2){
        if(this.permissions.find(p1 => p1.displayName == level1).modules.find(p2 => p2.displayName == level2).isGranted){
          return true;
        }else{
          return false;
        }
      }else if(level1){
        if(this.permissions.find(p1 => p1.displayName == level1).isGranted){
          return true;
        }else{
          return false;
        }
      }
    }else{
      return false;
    }



  }
}

<header id="main-header">
  <div class="header-inner">

    <div class="mobile-logo">
      <button mat-flat-button class="sidebar-btn expand-menu-button">
        <img src="/assets/icons/hamburger.svg" alt="expand menu icon" />
      </button>
      <a href="#" class="nav-logo">
        <img src="/assets/icons/logo.svg" alt="old mutual logo" />
      </a>
    </div>
    <div class="right-head-content">
      <div class="user-search">
        <span class="user-icon mobile-search">
          <img src="/assets/icons/mobile-search.svg" alt="Search">
        </span>
        <div class="user-icon notification">
          <img src="/assets/icons/bell-icon.svg" alt="notification" />
          <!-- <span class="notification-badge">4</span> -->
          <!-- <div class="notification-wraper">
            <div class="notifi-header">
              <h3>Notifications</h3>
              <a href="#" class="mark-read">Mark all as read</a>
            </div>
            <ul>
              <li>
                <div class="error-icon">
                  <img src="/assets/icons/high-priority.svg" alt="High Priority">
                  <img *ngIf="markedIcon" src="/assets/icons/high-priority-marked.svg" alt="High Priority Marked">
                </div>
                <div class="error-details">
                  <div class="error-heading">
                    <h4>Error(s) - Integration Monitoring</h4>
                    <div class="timer">2 min ago</div>
                  </div>
                  <p>2 errors are found under Integration Monitoring. Click on the link to View & Resolve the errors.</p>
                </div>
              </li>
              <li>
                <div class="error-icon">
                  <img src="/assets/icons/chart-bar.svg" alt="Chart Bar">
                  <img *ngIf="markedIcon" src="/assets/icons/chart-bar-marked.svg" alt="Chart Bar Marked">
                </div>
                <div class="error-details">
                  <div class="error-heading">
                    <h4>Start fund of the month</h4>
                    <div class="timer">6 hrs ago</div>
                  </div>
                  <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut.</p>
                </div>
              </li>
              <li>
                <div class="error-icon">
                  <img src="/assets/icons/heart-beat.svg" alt="Heart Beat">
                  <img *ngIf="markedIcon" src="/assets/icons/heart-beat-marked.svg" alt="Heart Beat Marked">
                </div>
                <div class="error-details">
                  <div class="error-heading">
                    <h4>Protect your health</h4>
                    <div class="timer">6 hrs ago</div>
                  </div>
                  <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut.</p>
                </div>
              </li>
              <li>
                <div class="error-icon">
                  <img src="/assets/icons/ionic-eye.svg" alt="Ionic Eye">
                  <img *ngIf="markedIcon" src="/assets/icons/ionic-eye-marked.svg" alt="Ionic Eye Marked">
                </div>
                <div class="error-details">
                  <div class="error-heading">
                    <h4>Activate UPI ID</h4>
                    <div class="timer">6 hrs ago</div>
                  </div>
                  <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut.</p>
                </div>
              </li>
            </ul>
          </div> -->
        </div>
        <span class="user-icon inbox" (click)="onNotificationOpen()">
          <img src="/assets/icons/inbox.svg" alt="Inbox Icon" />
          <!-- <span class="notification-badge">4</span> -->
        </span>
      </div>
      <div class="user-info">
        <p class="user-name">Hello, <span>{{username}}</span></p>
        <div class="user-photo">
          <img src="assets/images/user-image.png" alt="user photo" />
          <div class="profile-dropdown">
            <ul>
              <li><img src="/assets/icons/my-profile-icon.png" alt="profile" />My Profile</li>
              <li><img src="/assets/icons/settings-icon.png" alt="profile" />Settings</li>
              <li (click)="logout()"><img src="/assets/icons/profile-logout-icon.png" alt="profile" />Logout</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- <a mat-button class="back-page">
      {{backTitle}}
    </a> -->
    <nav aria-label="breadcrumb">
      <ul class="breadcrumb">
        <ng-container *ngFor="let breadcrumb of (breadcrumbs$ | async)">
          <li class="breadcrumb-item" aria-current="page"  *ngFor="let menu of breadcrumb.label">
            <button type="button" [ngStyle]="{'cursor': menu.isLink ? 'default' : 'pointer'}" [routerLink]="menu.url" [disabled]="menu.isLink">{{ menu.label }}</button>
          </li>
        </ng-container>
      </ul>
    </nav>    
  </div>
</header>



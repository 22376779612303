import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Pagination } from 'src/app/models/pagination/pagination.model';

import { User } from 'src/app/models/user/user.model';
import { Urls } from 'src/app/utils/Urls';

@Injectable({
  providedIn: 'root'
})

export class UserService {

  addUserIntegrationMapMutation = gql`
mutation addUserIntegrationMap($createUserIntegrationMap: CreateUserIntegrationMapDtoInput!) {
  addUserIntegrationMap(createUserIntegrationMap: $createUserIntegrationMap) {
    data{
      createUpdateUserIntegration {
        user_ID
        l1_Config_Integration_Item_ID
        mappingLevel
        createdAt
        updatedAt
        status
        errorType
        note
        id
      }
    }
    message
    validated
    success
    notificationType
    errorMessages {
      code
      message
    }
    validationMessages {
      field
      message
    }
  }
}
`;

userIntegrationMapQuery = gql`
query userIntegrationMapPagination($id: Int!){
  userIntegrationMapPagination(id: $id) {
    data {
      totalCount
      items {
        user_ID
        user_Name
        l1_Config_Integration_Item_ID
        l1_Config_Integration_Item_Name
        mappingLevel
        status
        errorType
        note
        id
      }
    }
    message
    validated
    success
    notificationType
    errorMessages {
      code
      message
    }
    validationMessages {
      field
      message
    }

  }
}
`;

errorTypeQuery = gql`
query eETypePagination($input: PaginationRequestDtoInput!){
  eETypePagination(input: $input) {
    data {
      totalCount
      items {
        name
        description
        id
      }
    }
    message
    validated
    success
    notificationType
    errorMessages {
      code
      message
    }
    validationMessages {
      field
      message
    }

  }
}
`;



  constructor(
    private http: HttpClient,
    private apollo: Apollo
  ) { }

  getUsers(isActive? : Boolean): Observable<any>{
    let params = new HttpParams();
    params = params.set('isPagination', false);

    if(isActive){
      params = params.set('active', 'true')
    }
    
    return this.http.get(Urls.omUsersUrl, {params});
  }

  getUserProfile(data: any): Observable<any>{
    let params = new HttpParams()
    .set("email", data.email);

    return this.http.post(Urls.userProfileUrl, null, {params});

  }

  getUserDetails(id: string): Observable<any>{

    return this.http.get(Urls.omUsersUrl+`/${id}`);

  }

  addUserProfile(data: any): Observable<any>{
    let params = new HttpParams();
    return this.http.post(Urls.usersUrl, data);
  }

  updateUserProfile(id: string, data: any): Observable<any>{
    let params = new HttpParams();
    return this.http.put(Urls.usersUrl+`/${id}`, data);
  }

  getUserProfilePermission(name: string): Observable<any>{
    return this.http.get(Urls.userProfilePermissionUrl+`/${name}`)
  }

  getUsersByRole(name: string): Observable<any>{
    return this.http.get(Urls.omUsersByRoleUrl+`/${name}`)
  }

  getUsersByScope(name: string): Observable<any>{
    return this.http.get(Urls.omUsersByScopeUrl+`/${name}`)
  }

  addUserIntegrationMap(data: any) {
    return this.apollo
       .mutate({
          mutation: this.addUserIntegrationMapMutation,
          variables: {
            createUserIntegrationMap: data
          },
          errorPolicy : 'all'
       })
       .pipe(map((response: any) => response.data.addUserIntegrationMap));
  }

  getUserIntegrationMap(id?: number): Observable<any[]> {
    return this.apollo
         .query<any[]>({
            query: this.userIntegrationMapQuery,
            variables: {
              id: id
            },
            fetchPolicy: 'no-cache',
            errorPolicy : 'all'
         })
         .pipe(map((response: any) => response.data));
  }

  deactivateUser(id: string, isActive: boolean, l1IdsToReplaceUsers): Observable<any>{

    let data = {
      l1IdsToReplaceUsers: l1IdsToReplaceUsers
    }

    let params = new HttpParams();
    if(l1IdsToReplaceUsers.length > 0){
      params = params.set('isReplacedUser', true);
    }else{
      params = params.set('isReplacedUser', false);
    }
    
    return this.http.put(Urls.omUsersUrl+`/${id}`+`/${isActive}`, data, {params});

  }

  getErrorTypes(pagination?: Pagination): Observable<any[]> {
    return this.apollo
         .query<any[]>({
            query: this.errorTypeQuery,
            variables: {
              input: pagination
            },
            fetchPolicy: 'no-cache',
            errorPolicy : 'all'
         })
         .pipe(map((response: any) => response.data));
  }


}

<div class="dialog-wrapper authorization-dailog">
  <h1 mat-dialog-title class="text-center">
    ACCESS DENIED
  </h1>
  <div mat-dialog-content class="text-center">
    <img src="/assets/icons/access-denied.svg" alt="Access Denied">
    <p class="authorization-para">Access Denied. You are not authorised to perform this operation.</p>
  </div>
  <div mat-dialog-actions class="mat-dialog-actions text-center">
    <button mat-flat-button class="btn primary-btn" mat-dialog-close>OKAY</button>
  </div>
</div>

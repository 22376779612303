// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //DEV ENV
  envName: "dev",
  bankingApiBaseUrl: "https://api.ihub.dev.omar.oldmutual.com/",
  apiBaseUrl: "https://roa-dev-agaugeatbpf3gcb3.z01.azurefd.net/",

  //UAT env
  // envName: "uat",
  // bankingApiBaseUrl: "https://api.ihub.uat.omar.oldmutual.com/",
  // apiBaseUrl: "https://roa-uat-bmb2b4c5dfhpbwek.z01.azurefd.net/",

  //SIT ENV
  // envName: "sit",
  // bankingApiBaseUrl: "https://roasitapigw.azure-api.net/",
  // apiBaseUrl: "https://omar-staging-ehbxfscmagd9d5gr.z01.azurefd.net/"

  //PROD env
  // accountName : "omarftp",
  // sasToken:"sp=racw&st=2023-04-03T06:45:54Z&se=2023-07-03T14:45:54Z&spr=https&sv=2021-12-02&sr=c&sig=vu5UI4D8iqxFBgHP7EV%2FmaZoRQ1HDSfdKDqG276J3CY%3D",
  // secretName: "sastokenprod",
  // secretCode: "dpaZQMqv8Pp4-AqRwy3yTuk4AuXB3PKyhsDt-IpNgZQOAzFu8yE9Cw==",
  // bankSubscriptionKey: "d8b2fb16f41241a1a8558201e963eafe",
  // bankAccountName: "omarftp",
  // bankContainerName: "bank-integration-sftp",
  // bankSasToken: "?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupyx&se=2023-12-17T21:39:16Z&st=2023-09-01T13:39:16Z&spr=https,http&sig=XE0P25XxeGMk9bidyEEF%2Bzt%2BdbM3fsrb2M69sfNBDgo%3D",
  // bankingApiBaseUrl: "https://api.ihub.omar.oldmutual.com/",
  // bankSecretName: "sastokenbankintegrationprod",
  // bankSecretCode: "dpaZQMqv8Pp4-AqRwy3yTuk4AuXB3PKyhsDt-IpNgZQOAzFu8yE9Cw==",
  // apiBaseUrl: "https://omar-prod-cqg8hre0dnbghze4.z01.azurefd.net/"

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor() {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let pathname = new URL(request.url).pathname;
    if(pathname && !pathname.includes('process-bank-account-statement')){
      request = localStorage.getItem('accessToken') ? request.clone({
        setHeaders: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`
        }
      }) : request.clone({});
    }


    return next.handle(request);
    
  }
}
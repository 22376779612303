
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
@Injectable()
export class AuthGuard  {
  constructor(private router: Router) {}

  canActivate(): boolean {
    const isLoggedIn = localStorage.getItem('isLoggedIn');
    if (isLoggedIn) {
      return true;
    }else{
      this.router.navigateByUrl('/login');
      return false;
    }
  }
}
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  constructor() { }

  getPermissions(){
    return JSON.parse(localStorage.getItem('permissions'));
  }

  isActionPermission(level1: string, level2: string, level3: string, action: string){
    let permissions = JSON.parse(localStorage.getItem('permissions'));

    if(level3){
      let permission = permissions.find(p1 => p1.displayName == level1).modules.find(p2 => p2.displayName == level2).modules.find(p3 => p3.displayName == level3);
      if(permission && permission.modules.find(p4 => p4.displayName == action && p4.isGranted == true)){
        return true;
      }else{
        return false;
      }
    }else if(level2){
      let permission = permissions.find(p1 => p1.displayName == level1).modules.find(p2 => p2.displayName == level2);
      if(permission && permission.modules.find(p4 => p4.displayName == action && p4.isGranted == true)){
        return true;
      }else{
        return false;
      }
    }else if(level1){
      let permission = permissions.find(p1 => p1.displayName == level1);
      if(permission && permission.modules.find(p4 => p4.displayName == action && p4.isGranted == true)){
        return true;
      }else{
        return false;
      }
    }

  }
}
